$deviceActivityWarningColor: #ff9e22;

.m-datatable.m-datatable--default {
  &.m-datatable--loaded>.m-datatable__table>.m-datatable__head .m-datatable__row>.m-datatable__cell {
    background-color: transparent;
  }

  >.m-datatable__table {
    background-color: transparent;
    min-height: auto !important;

    .m-datatable__body .m-datatable__row>.m-datatable__cell>span,
    .m-datatable__foot .m-datatable__row>.m-datatable__cell>span,
    .m-datatable__head .m-datatable__row>.m-datatable__cell>span {
      overflow: visible;
    }

    .m-datatable__body {
      .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>input:checked~span {
        background-color: var(--primary-color);
      }

      .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>span {
        background-color: #0A1627;
      }

      .m-datatable__cell {

        &:nth-child(2) span,
        &:nth-child(3) span,
        &:nth-child(6) span,
        &:nth-child(7) span,
        .error_status_yes,
        span.error_status_no,
        &:first-child span,
        &:first-child span.m-card-user__name {
          color: #ffffff !important;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.48px;
        }

        &:nth-child(2) span {
          color: #D5D900 !important;
          font-weight: 300 !important;
        }

        &:first-child span,
        &:first-child span.m-card-user__name {
          font-weight: 500 !important;
        }

        &:first-child span.m-card-user__name {
          margin-left: 10px;
        }

        &.error_status_yes {
          color: #FF5F59 !important;

          .warning {
            color: $deviceActivityWarningColor !important;
          }
        }

        span.table-row-details-head {
          color: #8f939b !important;
          font-size: 14px;
          font-weight: 500;
        }

        span.table-row-details-td {
          color: #ffffff !important;
          font-size: 14px;
          font-weight: 400;
        }

        &:nth-child(2) span.sm_id {
          font-weight: 300;
          letter-spacing: 0.48px;
          color: #fff !important;
          font-size: 16px;
        }
      }
    }

    >.m-datatable__head .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid {
      background-color: #0A1627;
    }

    >.m-datatable__head .m-datatable__row>.m-datatable__cell .m-checkbox.m-checkbox--solid>input:checked~span {
      background-color: var(--primary-color);
    }
  }

  >.m-datatable__pager {
    margin-bottom: 25px;

    >.m-datatable__pager-nav {
      margin-right: 20px;

      >li {
        >.m-datatable__pager-link {
          &:hover {
            text-decoration: none;
          }

          &:active,
          &:hover {
            border-color: var(--primary-color) !important;
            background-color: #0A1627 !important;
          }

          &.m-datatable__pager-link--first,
          &.m-datatable__pager-link--last,
          &.m-datatable__pager-link--next,
          &.m-datatable__pager-link--prev {
            background-color: transparent !important;
            border: 1px solid #D5D900;
          }

          &.m-datatable__pager-link--first:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--prev:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--last:not(.m-datatable__pager-link--disabled):hover,
          &.m-datatable__pager-link--next:not(.m-datatable__pager-link--disabled):hover {
            color: #fff;
          }

          &.m-datatable__pager-link--disabled {
            cursor: initial;
            background-color: #0A1627 !important;
            border: 1px solid #7C828B;

            &:hover {
              border: 1px solid #7C828B !important;
            }
          }

          &.m-datatable__pager-link--active {
            border: 1px solid var(--primary-color);
            background-color: transparent;
            color: #fff;

            &:hover {
              background-color: #0A1627 !important;
            }
          }
        }
      }
    }

    >.m-datatable__pager-info {
      display: inline-block !important;

      .MuiInputBase-root {
        width: 67px;
      }

      .bootstrap-select {
        width: 70px !important;
      }

      .m-datatable__pager-size {
        padding: 0 !important;
        height: 2.5rem !important;
        margin-left: 0;
        border-radius: 20px !important;
        background-color: transparent;
        color: #ffffff;
        border: 1px solid var(--primary-color);

        &:hover {
          cursor: pointer;
          background-color: var(--primary-color);
          color: #282a3c;

          .MuiNativeSelect-icon {
            fill: #0A1627;
          }

          option {
            &:hover {
              background-color: grey;
            }
          }
        }

        &:before,
        &:after {
          border: none !important;
        }

        &.show {
          >.btn.dropdown-toggle {
            background: var(--primary-color);
          }
        }

        .btn.dropdown-toggle {
          background-color: #0A1627;
          color: #ffffff;

          &:hover,
          &:focus {
            background: var(--primary-color);
          }
        }

        option {
          background-color: #fff;
        }

        .nativeSelect {
          padding-left: 10px;
          padding-top: 2px;
          padding-bottom: 0;
          font-weight: 400;
          font-size: 15px;
          height: 35px;
          line-height: 35px;
        }

        .nativeSelectMenuIcon {
          fill: var(--primary-color);
        }

        .nativeSelectRoot.nativeSelect.nativeSelectMenu {
          padding-right: 0;
        }
      }

      .m-datatable__pager-detail {
        margin-left: 9px;
        color: #575962;
        font-size: 14px;
        font-weight: 300;

        &.records {
          width: 203px;
          height: 15px;
          font-size: 14px;
          font-weight: 300;
          line-height: 23px;
          color: #8f939b;
        }
      }
    }
  }
}

.m-datatable__table {
  tr {
    border-bottom: 1px solid #0A1627;
  }

  .m-datatable__head {
    .m-datatable__row span {
      font-size: 16px;
      color: #8f939b !important;
      font-weight: 500;
      letter-spacing: 0.48px;
    }
  }
}

.m-datatable__row-detail {
  tr {
    border-bottom: none;
  }

  &.active-datatable-row {
    width: 100%;
  }

  .m-datatable__detail .m-datatable__row>.m-datatable__cell:last-child {
    padding: 5px 10px;
  }
}

tr.m-datatable__row.active-datatable-row {
  border-bottom: none;
}

.m-datatable__row.employees-table:hover {
  background-color: #0a1627;
}

.switcher-logging {
  span {
    display: flex;
    align-items: center;
  }
}

.chart-switcher {
  margin-left: 56px;

  span {
    font-size: 16px;
    color: #fff;
  }
}

.search-ip-btn {
  position: absolute;
  top: 20px;
  right: 0;
  min-width: 96px;
}

.work_status {
  border: 1px solid #7C828B;
  border-radius: 10rem;
  padding: 2px 10px;
}

.send-email-btn {
  &:hover {
    #send-email-icon {
      color: #fff !important;
    }
  }

  #send-email-icon {
    color: #898b96 !important;
  }
}

.paddingLeft {
  padding-left: 36px;
}

.yellow-warning-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background-color: var(--primary-color);
  border-radius: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: #0A1627;
}

.MuiNativeSelect-select:focus {
  background: transparent !important;
}

.access-label {
  border-radius: 15px;
  border: 2px solid #fff;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .chart-switcher {
    margin-left: 16px;
  }
}

.render-paid-license {
  margin: -2px 2px 2px 0;
}