.customTextField.tokenStringField {
  overflow: visible !important;

  .inputRoot {
    .inputField {
      padding-right: 30px;
    }
  }
}

.generateTokenBtn {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  font-weight: 500;
  position: absolute;
  right: 25px;
  top: 0px;
  border: 1px solid var(--primary-color);
  border-radius: 48px;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  margin-right: 5px;
  padding: 0px 16px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color);
    color: #000;
  }

  &:disabled {
    cursor: default;
    filter: brightness(0.5);
  }
}