.btn-tooltip {
  background-color: #0A1627 !important;
}

.btn-popper {
  border-radius: 6px;
}

.zev-zoho-link {
  font-size: 12px;
}
