input.datepicker-input {
  font-size: 16px !important;
  line-height: 21px;
  padding: 8.5px 18.5px;
  border: none !important;

  &:hover,
  &:focus {
    border: none !important;
  }
}

.datepicker-input-field {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: #7C828B;
    }

    &:hover,
    &:focus {

      .MuiOutlinedInput-notchedOutline {
        border-color: #7C828B;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #7C828B;
    }

    &:hover,
    &:focus {

      .MuiOutlinedInput-notchedOutline {
        border-color: #7C828B;
      }
    }
  }

  .inputRoot::after {
    border: none;
  }
}

.custom-date-input-warning {
  white-space: nowrap;
}