.mainFooter {
  display: none;
  text-align: center;
  height: 16px;
  line-height: 16px;

  .poweredBy {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    background: rgba(30, 31, 37, 1);
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    a {
      margin: 0 0 0 5px;
      color: #D5D900;
      font-size: inherit;
    }
  }
}

.main-table-container,
main> {
  .mainFooter {
    .poweredBy {
      background: none;
    }
  }
}