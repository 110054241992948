@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Thin.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Texta;
  font-display: swap;
  src: url("./assets/fonts/Texta-Heavy.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: Texta, sans-serif;
}

:root {
  --primary-color: #D5D900;
  --secondary-color: #fff;
  --error-color: #FF5F59;
  --success-color: #00975F;
  --dark-color: #888;
  --light-dark-color: #102B4A;
}
