$primary: var(--primary-color);
$wrapper-color: #0A1627;
$bg-color: #0A1627;

.user-settings {
  background-color: $bg-color;

  .MuiTabs-root {
    color: #fff;
  }

  .MuiButtonBase-root {
    opacity: 1;
    font-size: 16px;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #1e2939;
  }

  .Mui-selected {
    color: $primary;
  }

  .MuiTabs-indicator {
    background-color: $primary;
    height: 2px;
  }

  .tab-wrapper {
    border: 1px solid #1f2a39;
    border-radius: 7px;
  }
}

.software-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: max(15%, 10px);
}
